
a {
	font-size: 1.1em;
    font-weight: 700;
	text-transform: uppercase;
}
.nav-area a:hover {
   
  }
.nav-area {
	background: #fdfdfd;
    margin-right: 14%;
    margin-left: auto;
}
.nav-area:after {
	content: '';
	clear: both;
	display: block;
}
.nav-area ul {
	list-style: none;
	margin: 0;
}
.nav-area>ul>li {
	float:right;
	position: relative;
}
.nav-area ul li a {
    
	color: #000;
	padding: 1em;
	display: block;
}
.nav-area ul li:hover a {
	background: #fdfdfd;
}
.nav-area ul ul {
	position: absolute;
	padding: 0;
	width:280px;
	display: none;
	top: 100%;
	left: 0;
	
}
.nav-area ul li:hover>ul {
	display: block;
	border-radius: 12px;
}
.nav-area ul ul li:hover a {
	background: #fdfdfd;
     font-weight: 900;
}
.nav-area ul ul li {
	position: relative;
}
.nav-area ul ul ul {
	top: 0;
	left: 100%;
}
.nav-area ul ul ul li:hover a {
	background: #34495e;
}

.Logo_header{
    height: 40px;
    margin: 8px;
	margin-left: -12px;
}

.listitem{
    font-weight:700;
	text-align: center;
    font-size: .9em;
}


.profile{
	height: 25px;
	transform: scale(1.5);
	margin-right: 20px;
	float: right;
	margin-top: 1.1%;
}


@media (max-device-width:480px){
    .nav-area{
        display: none;
    }
	
.listitem{
    font-weight:700;
	text-align: center;
    font-size: .5em;
}

a {
	font-size: .5em;
    font-weight: 700;
	text-transform: uppercase;
}
}







